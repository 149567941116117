import { useAuth0 } from '@auth0/auth0-react';
import { FullStory } from '@fullstory/browser';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import type { NextPage } from 'next';
import { Channel } from 'pusher-js';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

import { CancelDeliveryModal } from 'src/components/Deliveries/CancelDeliveryModal';
import { identifySegment } from 'src/lib/segment';
import { CANCELED_ID } from 'src/lib/toast';
import { useOrderStore } from 'src/stores/orders';
import { Mode } from '../@types';
import { BatteryLevel, Deliveries } from '../components';
import PageContainer from '../components/shared/PageContainer';
import { usePartners, usePusher } from '../hooks';

const Home: NextPage = () => {
  const { user } = useAuth0();
  const { data: partnersData } = usePartners();
  const ldClient = useLDClient();

  const { setCanceledOrders } = useOrderStore();
  const { merchantChannel } = usePusher();

  useEffect(() => {
    if (user?.sub) {
      FullStory('setIdentity', {
        uid: user.sub,
        properties: {
          displayName: user?.nickname ?? user?.name ?? user?.email ?? 'merchant user',
          email: user?.email,
        },
      });

      ldClient?.identify({ key: user.sub });

      identifySegment(user.sub, {
        auth_id: user.sub,
        email: user.email,
      });
    }
  }, [ldClient, user]);

  useEffect(() => {
    toast.onChange(({ id, status }) => {
      if (status === 'removed' && id === CANCELED_ID) {
        console.log('toast - canceled order alert dismissed, resetting all canceled orders');
        setCanceledOrders([]);
      }
    });
  }, [setCanceledOrders]);

  const partnerIds = (partnersData && partnersData.map(({ id }) => id)) ?? [];

  return (
    <>
      <BatteryLevel />
      <PageContainer>
        <Deliveries partnerIds={partnerIds} mode={Mode.ACTIVE} merchantChannel={merchantChannel as Channel} />
        <CancelDeliveryModal />
      </PageContainer>
    </>
  );
};

export default Home;
